<template>
  <div>
  <AppBar :title="$t('shoppingCenters.UAEShoppingCenter')"/>
  <v-progress-linear v-if="loading" color="blue" class="progress" indeterminate></v-progress-linear>
  <v-card flat class="rounded-xl pt-2 pb-4">
    <v-card-text>
      <v-form ref="addForm" v-model="valid">
        <v-row>
          <v-col cols="12" md="5">
            <h4>{{ $t('editShoppingCenter.shopData') }}</h4>
            <v-text-field v-model="shop.name" :label="$t('editShoppingCenter.name')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.company" :label="$t('editShoppingCenter.company')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.subdomain" :label="$t('editShoppingCenter.subdomain')" :rules="subdomainRules"></v-text-field>
            <v-text-field v-model="shop.websiteUrl" :label="$t('editShoppingCenter.siteAddress')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.email" :label="$t('editShoppingCenter.email')" :rules="emailRules"></v-text-field>
            <h4 class="mt-4">{{ $t('editShoppingCenter.contact') }}</h4>
            <div class="mb-5">
              <MazPhoneNumberInput size="sm" show-code-on-list no-flags @update="phoneResult = $event" :rules="required" />
            </div>
            <v-text-field v-model="shop.contact.email" label="Email" :rules="emailRules"></v-text-field>
            <h4 class="mt-4">Social Media</h4>
            <v-text-field v-model="shop.socialMediaLinks.facebook" label="Facebook"></v-text-field>
            <v-text-field v-model="shop.socialMediaLinks.instagram" label="Instagram"></v-text-field>
            <v-text-field v-model="shop.socialMediaLinks.linkedIn" label="LinkedIn"></v-text-field>
            <v-text-field v-model="shop.socialMediaLinks.youTube" label="YouTube"></v-text-field>
            <h4 class="mt-4">{{ $t('editShoppingCenter.graphics') }}</h4>
            <v-file-input v-model="logo" :label="$t('editShoppingCenter.logo')" prepend-icon="mdi-camera" color="secondary" accept="image/*" :rules="required"></v-file-input>
            <v-file-input v-model="darkModeLogo" :label="$t('editShoppingCenter.darkModeLogo')" prepend-icon="mdi-camera" color="secondary" accept="image/*" :rules="required"></v-file-input>
            <v-file-input v-model="giftCardImage" :label="$t('editShoppingCenter.giftCardImage')" prepend-icon="mdi-camera" color="secondary" accept="image/*"></v-file-input>
          </v-col>
          <v-col cols="12" md="5" offset-md="1">
            <h4>API</h4>
            <v-text-field v-model="shop.authenticationData.clientPassCode" label="ClientPassCode" :rules="required"></v-text-field>
            <v-text-field v-model="shop.authenticationData.passCode" label="PassCode" :rules="required"></v-text-field>
            <v-text-field v-model="shop.authenticationData.passName" label="PassName" :rules="required"></v-text-field>
            <v-text-field v-model="shop.authenticationData.username" label="Username" :rules="required"></v-text-field>
            <v-text-field v-model="shop.authenticationData.deviceId" label="DeviceId" :rules="required"></v-text-field>
            <h4 class="mt-4">{{$t('editShoppingCenter.homePage')}}</h4>
            <v-text-field v-model="shop.header.title" :label="$t('editShoppingCenter.header')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.header.subtitle" :label="$t('editShoppingCenter.description')" :rules="required"></v-text-field>
            <h4 class="mt-4">Slider</h4>
            <v-file-input v-model="carouselSlides" :label="$t('editShoppingCenter.slides')" prepend-icon="mdi-camera" color="secondary" accept="image/*" multiple :rules="required"></v-file-input>
            <h4 class="mt-4">{{$t('editShoppingCenter.colors')}}</h4>
            <v-row>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>Primary</h5>
                    <v-sheet dark :color="shop.colorPalette.primary" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.colorPalette.primary }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.colorPalette.primary" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>Secondary</h5>
                    <v-sheet dark :color="shop.colorPalette.secondary" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.colorPalette.secondary }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.colorPalette.secondary" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>background</h5>
                    <v-sheet dark :color="shop.colorPalette.background" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.colorPalette.background }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.colorPalette.background" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>Accent</h5>
                    <v-sheet dark :color="shop.colorPalette.accent" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.colorPalette.accent }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.colorPalette.accent" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>Error</h5>
                    <v-sheet dark :color="shop.colorPalette.error" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.colorPalette.error }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.colorPalette.error" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>Success</h5>
                    <v-sheet dark :color="shop.colorPalette.success" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.colorPalette.success }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.colorPalette.success" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="12" md="6">
          <v-btn depressed color="secondary" @click="save" rounded> {{ $t('editShoppingCenter.save') }} </v-btn>
          <v-btn depressed class="ml-4" @click="$router.push({ name: 'ShoppingCentersUAE' })" rounded> {{ $t('editShoppingCenter.close') }} </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
  </div>
</template>
<script>
import Service from '@/services'
export default {
  name: 'NewShoppingCenter',
  data () {
    return {
      menu: false,
      valid: true,
      loading: false,
      phoneResult: null,
      shop: {
        name: null,
        company: null,
        email: null,
        header: {
          title: null,
          subtitle: null
        },
        contact: {
          email: null,
          phone: {
            countryCode: null,
            number: null
          }
        },
        socialMediaLinks: {
          facebook: null,
          instagram: null,
          linkedIn: null,
          youTube: null
        },
        colorPalette: {
          primary: '#000000',
          secondary: '#000000',
          background: '#000000',
          accent: '#000000',
          error: '#000000',
          success: '#000000'
        },
        authenticationData: {
          clientPassCode: null,
          passCode: null,
          passName: null,
          username: null,
          deviceId: null
        },
        subdomain: null,
        websiteUrl: null
      },
      logo: null,
      darkModeLogo: null,
      giftCardImage: null,
      carouselSlides: null,
      required: [(v) => !!v || this.$t('validation.fieldRequired')],
      emailRules: [
        v => !!v || this.$t('validation.emailRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('validation.incorrectEmailAddress')
      ],
      subdomainRules: [
        v => !!v || this.$t('validation.fieldRequired'),
        v => /^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/.test(v) || this.$t('validation.incorrectFormat')
      ],
      tokenRules: [
        v => !!v || this.$t('validation.fieldRequired'),
        v => (v || '').length === 9 || this.$t('validation.tokenHas9Digits')
      ]
    }
  },
  methods: {
    async save () {
      this.$refs.addForm.validate()

      if (!this.valid) return
      this.loading = true

      const formData = new FormData()
      // formData.append('Data', JSON.stringify(this.shop))
      formData.append('Data',
        JSON.stringify({
          Name: this.shop.name,
          Company: this.shop.company,
          Email: this.shop.email,
          Subdomain: this.shop.subdomain,
          WebsiteUrl: this.shop.websiteUrl,
          Header: {
            Title: this.shop.header.title,
            Subtitle: this.shop.header.subtitle
          },
          Contact: {
            Email: this.shop.contact.email,
            Phone: {
              CountryCode: this.phoneResult.countryCode,
              Number: this.phoneResult.nationalNumber
            }
          },
          SocialMediaLinks: {
            Facebook: this.shop.socialMediaLinks.facebook,
            Instagram: this.shop.socialMediaLinks.instagram,
            LinkedIn: this.shop.socialMediaLinks.linkedIn,
            YouTube: this.shop.socialMediaLinks.youTube
          },
          ColorPalette: {
            Primary: this.shop.colorPalette.primary,
            Secondary: this.shop.colorPalette.secondary,
            Background: this.shop.colorPalette.background,
            Accent: this.shop.colorPalette.accent,
            Error: this.shop.colorPalette.error,
            Success: this.shop.colorPalette.success
          },
          AuthenticationData: {
            ClientPassCode: this.shop.authenticationData.clientPassCode,
            PassCode: this.shop.authenticationData.passCode,
            PassName: this.shop.authenticationData.passName,
            Username: this.shop.authenticationData.username,
            DeviceId: this.shop.authenticationData.deviceId
          }
        }))

      if (this.logo) {
        formData.append('Logo', this.logo)
      }

      if (this.darkModeLogo) {
        formData.append('DarkModeLogo', this.darkModeLogo)
      }

      if (this.giftCardImage) {
        formData.append('GiftCardImage', this.giftCardImage)
      }

      if (this.carouselSlides) {
        for (let i = 0; i < this.carouselSlides.length; i++) {
          formData.append('CarouselSlides', this.carouselSlides[i])
        }
      }

      try {
        await Service.post('/DubaiShoppingCenters', formData)
        this.$refs.addForm.reset()
        this.$router.push({ name: 'ShoppingCentersUAE' })
      } catch (error) {}

      this.loading = false
    }
  }
}
</script>
