<template>
  <div>
  <AppBar :title="$t('shoppingCenters.shoppingCenter')"/>
  <v-progress-linear v-if="loading" color="blue" class="progress" indeterminate></v-progress-linear>
  <v-card flat class="rounded-xl pt-2 pb-4">
    <v-card-text>
      <v-form ref="addForm" v-model="valid">
        <v-row>
          <v-col cols="12" md="5">
            <h4>{{ $t('editShoppingCenter.shopData') }}</h4>
            <v-text-field v-model="shop.name" :label="$t('editShoppingCenter.name')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.company" :label="$t('editShoppingCenter.company')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.city" :label="$t('editShoppingCenter.city')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.subdomain" :label="$t('editShoppingCenter.subdomain')" :rules="subdomainRules"></v-text-field>
            <v-text-field v-model="shop.email" :label="$t('editShoppingCenter.email')" :rules="emailRules"></v-text-field>
            <h4 class="mt-4">{{ $t('editShoppingCenter.changeGraphics') }}</h4>
            <v-file-input v-model="backgroundImage" :label="$t('editShoppingCenter.background')" prepend-icon="mdi-camera" color="secondary" accept="image/*" :rules="required"></v-file-input>
            <v-file-input v-model="logo" :label="$t('editShoppingCenter.logo')" prepend-icon="mdi-camera" color="secondary" accept="image/*" :rules="required"></v-file-input>
            <v-file-input v-model="darkModeLogo" :label="$t('editShoppingCenter.darkModeLogo')" prepend-icon="mdi-camera" color="secondary" accept="image/*" :rules="required"></v-file-input>
            <v-file-input v-model="giftCardImage" :label="$t('editShoppingCenter.giftCardImage')" prepend-icon="mdi-camera" color="secondary" accept="image/*"></v-file-input>
            <p>
              {{ $t('imageRules.bg') }} <br>
              {{ $t('imageRules.logo') }} <br>
              {{ $t('imageRules.extensions') }}
            </p>
          </v-col>
          <v-col cols="12" md="5" offset-md="1">
            <h4>API</h4>
            <v-text-field v-model="shop.clientPassCode" label="ClientPassCode" :rules="required"></v-text-field>
            <v-text-field v-model="shop.passCode" label="PassCode" :rules="required"></v-text-field>
            <v-text-field v-model="shop.passName" label="PassName" :rules="required"></v-text-field>
            <v-text-field v-model="shop.username" label="Username" :rules="required"></v-text-field>
            <v-text-field v-model="shop.deviceId" label="DeviceId" :rules="required"></v-text-field>
            <v-text-field v-model="shop.mvcToken" label="MvcToken" :rules="tokenRules"></v-text-field>
            <h4>{{ $t('editShoppingCenter.pickupMethods') }}</h4>
            <v-checkbox v-model="shop.allowShippment" :label="$t('editShoppingCenter.shipping')"></v-checkbox>
            <v-checkbox v-model="shop.allowSelfPickup" :label="$t('editShoppingCenter.selfPickup')"></v-checkbox>
            <v-checkbox v-model="shop.allowClickAndCollect" :label="$t('editShoppingCenter.clickAndCollect')"></v-checkbox>
          </v-col>
          <v-col cols="12" md="5">
            <h4>{{ $t('editShoppingCenter.menuColor') }}</h4>
            <v-row>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.menuColor') }}</h5>
                    <v-sheet dark :color="shop.menuBackgroundColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.menuBackgroundColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.menuBackgroundColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.menuTextColor') }}</h5>
                    <v-sheet dark :color="shop.menuFontColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.menuFontColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.menuFontColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.headerTextColor') }}</h5>
                    <v-sheet dark :color="shop.headerFontColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.headerFontColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.headerFontColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.cardsBackgroundColor') }}</h5>
                    <v-sheet dark :color="shop.cardsBackgroundColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.cardsBackgroundColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.cardsBackgroundColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x >
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.cardsIconColor') }}</h5>
                    <v-sheet dark :color="shop.cardsIconColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.cardsIconColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.cardsIconColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.cardsTextColor') }}</h5>
                    <v-sheet dark :color="shop.cardsFontColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.cardsFontColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.cardsFontColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.cardsButtonColor') }}</h5>
                    <v-sheet dark :color="shop.cardsButtonColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.cardsButtonColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.cardsButtonColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.cardsButtonTextColor') }}</h5>
                    <v-sheet dark :color="shop.cardsButtonFontColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.cardsButtonFontColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.cardsButtonFontColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.footerTextColor') }}</h5>
                    <v-sheet dark :color="shop.footerFontColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.footerFontColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.footerFontColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5" offset-md="1">
            <v-text-field v-model="shop.headerFirstLineText" :label="$t('editShoppingCenter.header')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.headerLastLineText" :label="$t('editShoppingCenter.headerSecondLine')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.footerText" :label="$t('editShoppingCenter.footer')" :rules="required"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="12" md="6">
          <v-btn depressed color="secondary" @click="save" rounded> {{ $t('editShoppingCenter.save') }} </v-btn>
          <v-btn depressed class="ml-4" @click="$router.push({ name: 'ShoppingCenters' })" rounded> {{ $t('editShoppingCenter.close') }} </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
  </div>
</template>
<script>
import Service from '@/services'
export default {
  name: 'NewShoppingCenter',
  data () {
    return {
      menu: false,
      valid: true,
      loading: false,
      shop: {
        name: '',
        company: '',
        city: '',
        email: '',
        subdomain: '',
        menuBackgroundColor: '#000000',
        menuFontColor: '#000000',
        headerFirstLineText: '',
        headerFontColor: '#000000',
        headerLastLineText: '',
        cardsBackgroundColor: '#000000',
        cardsIconColor: '#000000',
        cardsFontColor: '#000000',
        cardsButtonColor: '#000000',
        cardsButtonFontColor: '#000000',
        footerFontColor: '#000000',
        footerText: '',
        clientPassCode: null,
        passCode: null,
        passName: null,
        username: null,
        deviceId: null,
        mvcToken: null,
        allowShippment: true,
        allowSelfPickup: false,
        allowClickAndCollect: false
      },
      backgroundImage: null,
      logo: null,
      darkModeLogo: null,
      giftCardImage: null,
      required: [(v) => !!v || this.$t('validation.fieldRequired')],
      emailRules: [
        v => !!v || this.$t('validation.fieldRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('validation.incorrectEmailAddress')
      ],
      subdomainRules: [
        v => !!v || this.$t('validation.fieldRequired'),
        v => /^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/.test(v) || this.$t('validation.incorrectFormat')
      ],
      tokenRules: [
        v => !!v || this.$t('validation.fieldRequired'),
        v => (v || '').length === 9 || this.$t('validation.tokenHas9Digits')
      ]
    }
  },
  methods: {
    async save () {
      this.$refs.addForm.validate()

      if (!this.valid) return
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')

      const formData = new FormData()
      formData.append('Data',
        JSON.stringify({
          Name: this.shop.name,
          Company: this.shop.company,
          City: this.shop.city,
          Email: this.shop.email,
          Subdomain: this.shop.subdomain,
          MenuBackgroundColor: this.shop.menuBackgroundColor,
          MenuFontColor: this.shop.menuFontColor,
          HeaderFirstLineText: this.shop.headerFirstLineText,
          HeaderFontColor: this.shop.headerFontColor,
          HeaderLastLineText: this.shop.headerLastLineText,
          CardsBackgroundColor: this.shop.cardsBackgroundColor,
          CardsIconColor: this.shop.cardsIconColor,
          CardsFontColor: this.shop.cardsFontColor,
          CardsButtonColor: this.shop.cardsButtonColor,
          CardsButtonFontColor: this.shop.cardsButtonFontColor,
          FooterFontColor: this.shop.footerFontColor,
          FooterText: this.shop.footerText,
          ClientPassCode: this.shop.clientPassCode,
          PassCode: this.shop.passCode,
          PassName: this.shop.passName,
          Username: this.shop.username,
          DeviceId: this.shop.deviceId,
          MvcToken: this.shop.mvcToken,
          AllowShippment: this.shop.allowShippment,
          AllowSelfPickup: this.shop.allowSelfPickup,
          allowClickAndCollect: this.shop.allowClickAndCollect,
          Tenant: countryCode
        })
      )

      if (this.logo) {
        formData.append('Logo', this.logo)
      }

      if (this.darkModeLogo) {
        formData.append('DarkModeLogo', this.darkModeLogo)
      }

      if (this.backgroundImage) {
        formData.append('BackgroundImage', this.backgroundImage)
      }

      if (this.giftCardImage) {
        formData.append('GiftCardImage', this.giftCardImage)
      }

      try {
        await Service.post('/ShoppingCenters', formData)
        this.$refs.addForm.reset()
        this.$router.push({ name: 'ShoppingCenters' })
      } catch (error) {}

      this.loading = false
    }
  }
}
</script>
